<template>
  <div class="pt-5 ">
    <a-upload
      name="avatar"
      method=""
      :accept="accept"
      list-type="picture-card"
      class="avatar-uploader"
      :show-upload-list="false"
      :before-upload="beforeUpload"
      !openFileDialogOnClick
      @change="handleChange"
    >
      <img v-if="imageUrl" class="object-contain max-h-[100px] max-w-[150px]" :src="imageUrl" alt="avatar" />
      <div v-else>
          <PlusOutlined :style="{fontSize: '30px'}" v-if="!isCamera"></PlusOutlined>
          <CameraOutlined :style="{fontSize: '30px'}" v-if="isCamera"></CameraOutlined>
          <div class="ant-upload-text font-bold">{{text}}</div>
      </div>
    </a-upload>
    <div v-if="this.isEmpty" class="text-red-500 pt-2">
        Please insert image here
    </div>
  </div>
</template>
<script>
import { PlusOutlined } from '@ant-design/icons-vue';
import { CameraOutlined } from '@ant-design/icons-vue';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default {
    props:{
        componentId:{
            type: String,
            required: true,
        },
        text:{
            type: String,
            required:false,
        },
        accept:{
          type: String,
          required:true,
        },
        isCamera:{
          type: Boolean,
          required:false,
        }
    },
  data() {
    return {
      loading: false,
      imageUrl: '',
      isEmpty: false,
      exceed:false,
    };
  },
  components:{
    PlusOutlined,
    CameraOutlined
  },
  methods: {
    checkEmpty() {
    if(!this.imageUrl) {
       this.isEmpty = true;
    }else {
      this.isEmpty = false;
    }
  },
    handleChange(info) {
    //   if (info.file.status === 'uploading') {
    //     this.loading = true;
    //     return;
    //   }
    //   if (info.file.status === 'done') {
        // Get this url from response in real world.
        // console.log(info);
        // console.log(info.file);
        let that = this;

        getBase64(info.fileList[0].originFileObj, imageUrl => {
          // console.log(imageUrl);  
          that.imageUrl = imageUrl;
          that.loading = false;
            that.$emit('changed',{
              key: this.componentId,
              value:imageUrl.replace('data:image/png','data:image/jpeg'),
            });
        });
       
          // console.log(that.imageUrl);
    //   }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 25;
      if (!isLt2M) {
        this.$message.error('Image must smaller than 25MB!');
      }
      return !isJpgOrPng && isLt2M;
    },
  },
};
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 130px !important;
  height: 130px !important;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
import { createStore } from "vuex";

export default createStore({
  state: {
    logged:false,
    forgot_password_payload:{
      uuid:null,
    },
    election_config:{
      date_start : null,
      date_end: null,
      server_time:null,
      can_vote:null,
      voted:null,
      election_registered:null,
      voting_ended:null,
    },
    credentials: {
      first_name:null,
      last_name:null,
      middle_name:null,
      gender:null,
      regular:null,
      account_no : null,
      serial_no : null,
      email : null,
      mobile : null,
      tin:null,
      name : null,
      username : null,
      password : null,
      membership_date:null,
      status:null,
      registered_date:null,
      qr : null,
      uuid : null,
      hash : null,
      key : null,
    },
    membership_details: {
      ref_account:null,
      mem_type:null,
      ref_name:null,
      confirmed:null,
      interview_date: null,
      pso:null,
      citizenship:null,
      sss_number:null,
      gsis_number:null,
      first_name: null,
      last_name: null,
      middle_name: "",
      rank: null,
      birth_date: null,
      place_of_birth: null,
      gender: null,
      suffix:null,
      max_length:null,
      minimum_length:null,
      civil_status: null,
      snap:null,
      afp_serial_number: null,
      branch_of_service: null,
      employee_status: null,
      gender_text:null,
      employee_status_text:null,
      civil_status_text:null,
      branch_of_service_text:null,
      rank_text:null,
      schedule_key:null,
      pso_text:null,
      tin: null,
      mobile: null,
      email: null,
      re_email:null,
      mofirst_name: null,
      molast_name: null,
      momiddle_name: null,  
      fafirst_name: null,
      falast_name: null,
      famiddle_name: null, 
      spfirst_name: null,
      splast_name: null,
      spmiddle_name: null,
      spfirst_name2: null,
      splast_name2: null,
      spmiddle_name2: null,
      spfirst_name3: null,
      splast_name3: null,
      spmiddle_name3: null,
      spfirst_name4: null,
      splast_name4: null,
      spmiddle_name4: null,
      sponsor_nr:null,
      sponsor_name:null,
      sponsor_id:null,
      docs:[],
      beneficiaries:[],  
      spouse:[],
      resstreet_address: null,
      reszip_code: null,
      resprovince: null,
      rescity: null,
      resbrngy: null,
      altstreet_address: null,
      altzip_code: null,
      altprovince: null,
      altcity: null,
      altbrngy:null,
      signature:null,
      front:null,
      back:null,
      sc:null,
      sc_agree:null,
      wcp_agree:null,
      cisa_agree:null,
      pmes_dt:null,
      email_pin:null,
      phone_pin:null,
      exist:false,
      interview_mode:null,
      rank_required:false,
    },
    references:{
      server_time:null,
      branch_of_service:[],
      employment_status_options:[],
      branches:[],
      civil_status:[],
      gender:[],
      ranks:[],
    }
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});

<template>
  <div id="app">
    <div class="container">
      <div class="container max-w-4xl">
        <VueSignaturePad
          id="signature"
          width="100%"
          height="150px"
          ref="signaturePad"
          :options="options"
        />
      </div>
      <div class="buttons">
        <Button @click="undo" button_text="CLEAR"/>
        <Button @click="save" button_text="SAVE" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Button from '@/components/controls/ButtonControl.vue';
export default defineComponent({
  name: "App",
  data: () => ({
    options: {
      penColor: "#000000",
      backgroundColor: "white",
    },
  }),
  props:{
    id:{
        type: String,
        required: true,
    }
  },
  components:{
    Button,
  },
  methods: {
    undo() {
      this.$refs.signaturePad.clearSignature();
    },
    save() {
      const { data } = this.$refs.signaturePad.saveSignature();

    //   alert("Open DevTools see the save data.");
      this.$emit('changed',{
              key: this.id,
              value:data.replace('data:image/png','data:image/jpeg'),
            });
    },
    change() {
      this.options = {
        penColor: "#000000",
      };
    },
    resume() {
      this.options = {
        penColor: "#000000",
      };
    },
  },
});
</script>

<style>
#signature {
  border: double 3px;
  border-radius: 5px;
  background-image: white;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.container {
  background:white !important;
  width: "100%";
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}
</style>

<template>
 <Container back_ground_color='items-center h-fit max-w-4xl ' >
      <Card max_width="max-w-7xl p-2 relative" :title="'OTHER Information'" :service_type="membership_type" :test_date="test_date">
          <div class="w-full font-bold" v-show="false">
            <span v-for="(items,index) in sched" :id="index" :key="index" v-text="items"/>
          </div>
          <div class="w-full flex flex-col p-4 xs:p-1 space-y-2 ">
          <div class="w-full flex flex-col space-y-2">
            <div class="flex  text-lg font-bold mb-1  ">
              UPLOAD ID
            </div>
            <div class="border border-gray-300 rounded shadow flex flex-col">
              <div class="flex flex-row md:flex-row sm:flex-col xs:flex-col xs:space-x-0">
              <div class=" p-4 my-2 w-full flex flex-col">
              <div class="mb-2 self-center font-bold  ">Upload an image of your ID (FRONT) </div>
                <div class="self-center">
                  <UploadFile id="front_id"   ref="frontImage" accept="image/png, image/jpeg" text="Upload" :componentId="'front_id'" required @changed="update"/>
                </div>
              </div>
              <div class=" p-4 my-2 w-full flex flex-col   ">
                <div class="mb-2 self-center font-bold">Upload an image of your ID (BACK) </div>
                 <div class="self-center">
                   <UploadFile id="back_id" ref="backImage" accept="image/png, image/jpeg" text="Upload" :componentId="'back_id'" required @changed="update"/>
                 </div>
              </div>
              <div class=" p-4 my-2 w-full flex flex-col" v-show="show_dependent_id">
              <div class="mb-2 self-center font-bold  ">Upload an image of your sponsor ID </div>
                <div class="self-center">
                  <UploadFile id="sponsor_id" ref="sponsor_id" accept="image/png, image/jpeg" text="Upload" :componentId="'sponsor_id'" required @changed="update" />
                </div>
              </div>
              </div>
              <div class="flex flex-row md:flex-row sm:flex-col xs:flex-col xs:space-x-0">
              <div class=" p-4 my-2 w-full flex flex-col xs:hidden" @click="this.openCamera">
              <div class="mb-2 self-center font-bold ">Take a selfie with your ID & Signature</div>
                <div class="self-center flex flex-col items-center pt-5">
                  <div class="border border-dashed border-gray-300 hover:border-blue-400 h-32 w-32 cursor-pointer bg-gray-50 flex rounded flex-col place-content-center">
                      <div class="m-auto max-h-1 -mb-1" v-if="!selfie">
                        <CameraOutlined :style="{fontSize: '30px'}" />
                      </div>
                      <span v-text="'Take a Selfie'" class="m-auto font-bold text-gray-500" v-if="!selfie"/>
                      <img :src="selfie" v-else/>
                    </div>
                     <div v-if="this.selfie_empty" class="text-red-500 pt-2 ">
                      Please take a selfie
                    </div>
                  <!-- <UploadFile id="front_id"   ref="frontImage"  :componentId="'front_id'" required @changed="update"/> -->
                </div>
              </div>
                <div class="self-center md:hidden sm:hidden flex flex-col items-center">
                  <div class="mb-2 self-center font-bold ">Take a selfie with your ID & Signature</div>
                  <UploadFile id="selfie" ref="selfie" :accept="'null'" isCamera text="Take A Selfie" :componentId="'selfie'" required @changed="update" />
                   <div v-if="this.selfie_empty" class="text-red-500 pt-2 ">
                      Please take a selfie
                    </div>
                </div>
            <div class=" p-4 my-2 w-full flex flex-col" @click="show_signature_pad= true">
              <div class="mb-2 self-center font-bold  ">Add Signature</div>
                <div class="self-center flex flex-col items-center pt-5">
                  <div class="border border-dashed border-gray-300 hover:border-blue-400 h-32 w-32 cursor-pointer bg-gray-50 flex rounded flex-col place-content-center">
                      <div class="m-auto max-h-1 -mb-1" v-if="!signature">
                        <img src="@/assets/images/papsi_signature_icon.svg" :style="{height: '30px',width: '30px'}" class="h-5"/>
                      </div>
                      <span v-text="'Signature'" class="m-auto  text-gray-500" v-if="!signature"/>
                      <img :src="signature" v-else/>
                    </div>
                     <div v-if="this.signature_empty" class="text-red-500 pt-2">
                        Please insert signature here
                      </div>
                  <!-- <UploadFile id="front_id"   ref="frontImage"  :componentId="'front_id'" required @changed="update"/> -->
                </div>
              </div>
              </div>
              <SignaturePad id="signature" class="self-center" @changed="update" v-if="show_signature_pad"/>
               <!-- <div class="w-full p-5 flex flex-col text-2xl font-bold text-theme-primary max-w-lg self-center" v-if="show_selfie_modal">
                  <TakeAPhoto id="selfie" ref="selfie" required @changed="update" v-if="show_selfie_modal"/>
              </div> -->
            </div>

            <div class="w-full">
              <div class="flex  text-lg font-bold mb-1  ">
                OTHER REQUIREMENTS 
              </div>
              <div class="border border-gray-300 p-2 rounded shadow flex flex-col">
                <Dropdown notSorted uppercase nonumbers class="pb-1" :id="'dep_selected'" v-if="this.$store.state.membership_details.branch_of_service=='DEP'" :componentId="'dep_selected'"  required type="text" :items="dependent_type" :placeholder="'DEPENDENT TYPE'" @changed="chekDependentType"/>
                <p v-text="this.message" class="text-red-500 w-full pb-2" v-if="this.message!=null || this.message!=''"/>
                 <a-upload-dragger
                    v-model:fileList="fileList"
                    list-type="picture"
                    :multiple="true"
                    method=""
                    ref="uploaded_file"
                    :maxCount="5"
                    accept="image/png, image/jpeg"
                    @change="handleChange"
                    :beforeUpload="beforeUpload"
                  >
                    <p class="ant-upload-drag-icon">
                      <InboxOutlined></InboxOutlined>
                    </p>
                    <p class="ant-upload-text">Click to upload</p>
                    <template #itemRender="{file,actions}">
                      <div class="w-full flex border border-gray-300 mt-2 p-2  space-x-1">
                        <div class="border flex justify-center  w-full space-x-2 p-3 items-center">
                           <a-image
                            :width="65"
                            :height="65"
                            :preview="false"
                            :src="file.thumbUrl"
                             class="ant-image"
                          />
                          <p v-text="file.name" class="content-center truncate text-lg font-bold w-1/2"/>
                         
                          <Dropdown notSorted uppercase nonumbers :ref="file.uid" @click="showSelectionList" required :componentId="file.uid"  :id="file.uid" type="text" :items="selection" :placeholder="'Description'" @changed="updateUploadedList(file.uid)"/>
                           <TextField uppercase nonumbers :ref="file.uid+'1'" :id="file.uid" type="text" :placeholder="'Remarks'" @changed="update"/>
                          <div @click="actions.remove" class="cursor-pointer content-center">
                              <DeleteOutlined />
                          </div>
                        </div>
                      </div>
                    </template>
                  </a-upload-dragger>

              </div>
            </div>


          </div>
            <div class="flex text-lg font-bold mb-1  ">
              INTERVIEW PSO, DATE & TIME
            </div>
             <div class=" flex flex-row space-x-1 xs:space-y-3 md:flex-row sm:flex-col xs:flex-col xs:space-x-0">
                <Dropdown required :id="'branch_code'" class="w-full" placeholder="Select PSO" componentId="branch_code" ref="Branch" :items="branches" @changed="update"/>
                <Dropdown required :id="'interview_mode'" class="w-full" placeholder="Mode of Interview" componentId="interview_mode" ref="interview_mode" :items="meeting_mode" @changed="update"/>
              </div>
              <div class=" flex flex-row space-x-1 xs:space-y-3 md:flex-row sm:flex-col xs:flex-col xs:space-x-0">
                <DatePicker :dates="enabled_dates" required customCalendar placeholder="Interview Date" componentId="interview_date" ref="IntDate" interview @changed="update"/>
                <Dropdown notSorted required :id="'interview_time'" class="w-full" placeholder="Time" componentId="interview_time" ref="interview" :items="new_list" @changed="update"/>
              </div>
          <div class="flex  text-lg font-bold mb-1" v-show="false">
               <span v-text="'REFFERED BY'" class=" "/>
            </div>
            <Container v-show="false" class="h-fit" flex_direction='flex-row space-x-1  pb-5 md:flex-row sm:flex-col xs:flex-col xs:space-x-0' back_ground_color="transparent">
                <TextField :max_length="6 " uppercase nonumbers :id="'ref_account'" type="text" :placeholder="'Account/Serial No.'" @changed="update"/>
                <TextField uppercase nonumbers :id="'ref_name'" type="text" :placeholder="'Name'" @changed="update"/>
              </Container>
               <div class="flex  text-lg font-bold mb-1  ">
                  <span v-text="'AGREEMENT'"/>
                </div>
              <div class="border bg-white border-gray-300 rounded shadow flex flex-col">
                <div class="w-full items-center flex flex-wrap pt-2">
                     <CheckBox id="sc_agree" class="text-left pt-2 pl-2 xs:pl-2" @changed="updateSc" :disabled="disable_sc_check" :checkbox_width="25">
                       <span v-text="lang('allow.checkbox.text.first')" class="font-thin text-lg xs:text-md"/>
                      </CheckBox>
                      <div class="flex flex-row items-center">
                        <div class="max-w-xs">
                          <TextField :id="'sc'" :disable="disable_sc" sc ref="sc" type="number" :initial="branch_of_service == 'DEP'?'0':''+sc" required class="p-2 xs:max-w-[230px]  xs:ml-6" :placeholder="'AMOUNT'" @changed="update"/>
                        </div>
                      </div>
                      <!-- <span v-text="lang('allow.checkbox.text.second')" class="font-thin text-lg w-full ml-10 -mt-3 mb-2"/> -->
                      <span v-text="lang('allow.checkbox.text.third')" class="font-thin text-lg w-full ml-10 -mt-3 mb-2 xs:text-md"/>
                      
                    <!-- <CheckBox id="allow" class="text-left pt-2 pl-3 xs:pl-0" :checkbox_width="25" @changed="sameAddress"> -->
                    
                </div>
                <div>
                  <CheckBox id="wcp_agree" class="text-left pt-2 pl-2 xs:pl-2" @changed="updateWcp" :checkbox_width="25">
                    <span v-text="lang('qualified.checkbox.text')" class="xs:text-md"/>
                  </CheckBox>
                </div>
                <div class="pb-2">
                  <CheckBox required id="cisa_agree" class="text-left pt-2  xs:pl-0" @changed="updateCisa" :checkbox_width="25">
                    <span v-text="lang('authorized.checkbox.text')" class="xs:text-md"/>
                  </CheckBox>
                </div>
              </div>
          </div>
      </Card>
  </Container>
</template>
<script>
import { defineComponent } from 'vue'
import Container from '@/components/sections/ContainerView.vue';
import Card from '@/components/controls/CardView.vue';
import TextField from '@/components/controls/TextBoxField.vue';
import CheckBox from '@/components/controls/CheckBox.vue';
// import UploadFile from '@/components/controls/UploadControl.vue';
import UploadFile from '@/components/controls/AntUpload.vue';
import Dropdown from '@/components/controls/DropDownControl.vue';
import DatePicker from '@/components/controls/DatePicker.vue';
// import TakeAPhoto from '@/components/controls/TakeAPhoto.vue';
import { CameraOutlined } from '@ant-design/icons-vue';
// import Modal from '@/components/controls/ModalControl.vue';
import SignaturePad from '@/components/controls/SignaturePad.vue';
import moment from 'moment';
import mixin from "@/mixin";
import { InboxOutlined,DeleteOutlined } from '@ant-design/icons-vue';
// import date from 'date-and-time';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default defineComponent({
  components: {
    Container,
    Card,
    TextField,
    UploadFile,
    Dropdown,
    DatePicker,
    InboxOutlined,
    DeleteOutlined,
    // TakeAPhoto,
    // Modal,
    CameraOutlined,
    SignaturePad,
    CheckBox
  },
  mounted(){
     let that = this;
     let store = that.$store.state;
     let state = store.membership_details;
     
     that.service_status = state.employee_status;
     that.branch_of_service = state.branch_of_service;

  },
  props:{
    id:{
      type:String,
      required:true,
    }
  },
  data(){
    return {
      branches:[],
      // branches:['PAFCPIC SATELLITE OFFICE - TARLAC','PAFCPIC SATELLITE OFFICE - ISABELA','PAFCPIC SATELLITE OFFICE - MAGSAYSAY','PAFCPIC SATELLITE OFFICE - BICOL','PAFCPIC SATELLITE OFFICE - BUTUAN','PAFCPIC SATELLITE OFFICE - ILOILO','PAFCPIC SATELLITE OFFICE - CEBU','PAFCPIC SATELLITE OFFICE - SAMAR','PAFCPIC SATELLITE OFFICE - PAGADIAN','PAFCPIC SATELLITE OFFICE - CDO','PAFCPIC SATELLITE OFFICE - DABAO','PAFCPIC SATELLITE OFFICE - COTABATO','PAFCPIC SATELLITE OFFICE - ILOCOS','PAFCPIC SATELLITE OFFICE - GHQ','PAFCPIC SATELLITE OFFICE - ZAMBOANGA','PAFCPIC SATELLITE OFFICE - TANAY','PAFCPIC SATELLITE OFFICE - LUCENA','PAFCPIC SATELLITE OFFICE - GENSAN','PAFCPIC SATELLITE OFFICE - JAMINDAN','PAFCPIC SATELLITE OFFICE - CAPAS','PAFCPIC SATELLITE OFFICE - NEGROS','PAFCPIC SATELLITE OFFICE - LEGAZPI','PAFCPIC SATELLITE OFFICE - PALAWAN'],
      front_id:null,
      back_id:null,
      fileList:[],
      message:null,
      it:"",
      membership_type:'',
      ref_account:null,
      disable_sc:false,
      disable_sc_check:false,
      ref_name:null,
      interview_mode:null,
      signature:null,
      branch_code:null,
      branch_name:null,
      interview_time:null,
      interview_time_list:[],
      selection:[],
      dependent_type:["PARENTS","CHILD","SPOUSE"],
      dependent_type_selected:null,
      from:null,
      to:null,
      new_list:[],
      sched:[],
      scheds:[],
      sc:0,
      test_date:null,
      sc_agree:0,
      wcp_agree:0,
      cisa_agree:0,
      interview_date:null,
      selfie_empty:false,
      signature_empty:false,
      selfie:null,
      schedule:null,
      enabled_dates:[],
      spouse_list:['spouse'],
      show_selfie_modal:false,
      show_signature_pad:false,
      sponsor_id:null,
      branch_of_service:null,
      service_status:null,
      show_dependent_id:false,
      meeting_mode: ['ZOOM','VIBER'],
      uploaded_list:[]
    }
  },
 mixins: [
    mixin,
  ],
  methods:{
    onRemove(){
      return false;
    },
    chekDependentType(e){
      let that = this;
      that.dependent_type_selected = e.value;
      that.fileList = [];
    },
    updateUploadedList(uid){
      let that = this;
      let ref = that.$refs

      for(let indicator = 0; indicator<that.uploaded_list.length; indicator++){
        // console.log(uid,that.uploaded_list[indicator].ID);
        if(uid == that.uploaded_list[indicator].ID){
          that.uploaded_list[indicator].REMARKS = ref[uid].selected;
          if(ref[uid].selected=="OTHERS"){
            ref[uid+"1"].showField();
          }
        }
      }
      // console.log(that.uploaded_list);
      that.checkUploadedFiles();
    },
    updateUploadedListAll(){
      let that = this;
      let ref = that.$refs

      if(that.uploaded_list.length>0){
        for(let indicator = 0; indicator<that.uploaded_list.length; indicator++){
          try{
              that.uploaded_list[indicator].REMARKS = ref[that.uploaded_list[indicator].ID].selected;
          }catch(e){
            // console.log(message);
          }
        }
      }
      
      // console.log(that.uploaded_list);
    },
    showSelectionList() {
      let that = this;
      let store = that.$store;
      let state = store.state.membership_details;
      // console.log(that.branch_of_service);
      if(state.branch_of_service == "PAF" || state.branch_of_service == "PA" || state.branch_of_service == "PN"){
        // console.log(state.employee_status);
        if(state.employee_status == "A"){
          that.selection = ["Enlistment/Re-enlistment Order","Photocopy of Military ID","Others"]
        } else if(state.employee_status == "I") {
          that.selection = ["Retirement Order","Honorably Discharged Order","Photocopy of Pensioner / Government ID","Others"]
        }
      }
      else
      {
        if(that.dependent_type_selected == "PARENTS"){
          that.selection = ["Marriage Contract of Parents","Sponsor's Birth Certificate","Photocopy of PAFCPIC ID of Sponsor","Photocopy of Government Issued ID","Others"]
        } else if(that.dependent_type_selected == "SPOUSE"){
          that.selection = ["Marriage Contract","Photocopy of PAFCPIC ID of Sponsor","Photocopy of Government Issued ID","Others"]
        } else if(that.dependent_type_selected == "CHILD"){
          that.selection = ["Marriage Contract of Parents","Birth Certificate","Photocopy of PAFCPIC ID of Sponsor","Photocopy of valid ID","Marriage Contract(for married female child)","Others"]
        }
        
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 25;
      if (!isLt2M) {
        this.$message.error('Image must smaller than 25MB!');
      }
      return !isJpgOrPng && isLt2M;
    },
    pushBranchRef(){
      let that = this;
      let store = that.$store;
      let state = store.state;
      let references = state.references;
      this.branches = [];
       that.membership_type = state.membership_details.mem_type;
         for(let i = 0; i < references.branches.length; i++){
          if(references.branches[i].schedule.length> 0){
            let branch_name = references.branches[i].label.replace('PAFCPIC SATELLITE  OFFICE - ','');
            branch_name = branch_name.replace('PAFCPIC SATELLITE OFFICE - ','');
            this.branches.push(branch_name);
          }
       }
      
    },
    checkActive() {
      let that = this;
      let ref = that.$refs;
      let state = that.$store.state;
      let enre_exist = false;
      let pmid_exist = false;
      let selection1 = "Enlistment/Re-enlistment Order";
      let selection2 = "Photocopy of Military ID";
      // console.log(state.membership_details.branch_of_service);
      if(state.membership_details.branch_of_service!="DEP" && state.membership_details.employee_status=="A") {
         for(let line_checker = 0 ; line_checker < that.uploaded_list.length; line_checker++){
          // console.log(ref[that.uploaded_list[line_checker].ID].selected);
          if(ref[that.uploaded_list[line_checker].ID].selected == selection1.toUpperCase()) {
            enre_exist = true;
          } else if (ref[that.uploaded_list[line_checker].ID].selected == selection2.toUpperCase()) {
            pmid_exist = true;
          }
        }
        if(enre_exist && pmid_exist) {
          state.membership_details.uploaded_list_error = false;
          that.message = "";
        }else{
          state.membership_details.uploaded_list_error = true;
          if(!enre_exist) {
            that.message = "Missing " + selection1;
          }
           if(!pmid_exist) {
            that.message = "Missing " + selection2;
          }
        }
      }
    },
    checkRetired() {
      let that = this;
      let ref = that.$refs;
      let state = that.$store.state;
      let ro_exist = false;
      let hd_exist = false;
      let ppid_exist = false;
      let selection1 = "Retirement Order";
      let selection2 = "Honorably Discharged Order";
      let selection3 = "Photocopy of Pensioner / Government ID";

      if(state.membership_details.branch_of_service!="DEP" && state.membership_details.employee_status=="I") {
         for(let line_checker = 0 ; line_checker < that.uploaded_list.length; line_checker++){
          // console.log(ref[that.uploaded_list[line_checker].ID].selected);
          if(ref[that.uploaded_list[line_checker].ID].selected == selection1.toUpperCase()) {
            ro_exist = true;
          } else if (ref[that.uploaded_list[line_checker].ID].selected == selection2.toUpperCase()) {
            hd_exist = true;
          } else if (ref[that.uploaded_list[line_checker].ID].selected == selection3.toUpperCase()) {
            ppid_exist = true;
          }
        }
        if(ro_exist && hd_exist && ppid_exist) {
          that.message="";
          state.membership_details.uploaded_list_error = false;
        }else{
          state.membership_details.uploaded_list_error = true;
          if(!ro_exist) {
            that.message = "Missing " + selection1;
          }
          if(!hd_exist) {
            that.message = "Missing " + selection2;
          }
          if(!ppid_exist) {
            that.message = "Missing " + selection3;
          }
        }
      }

    },
    checkDependentsParents() {
      let that = this;
      let ref = that.$refs;
      let state = that.$store.state;


      let parents_bcs_exist = false;
      let parents_mcp_exist = false;
      let parents_ppid_exist = false;
      let parents_govid_exist = false;

      let selection_parent1 = "Marriage Contract of Parents";
      let selection_parent2 = "Sponsor's Birth Certificate";
      let selection_parent3 = "Photocopy of PAFCPIC ID of Sponsor";
      let selection_parent4 = "Photocopy of Government Issued ID";

      if(state.membership_details.branch_of_service=="DEP" && that.dependent_type_selected == "PARENTS") {
         for(let line_checker = 0 ; line_checker < that.uploaded_list.length; line_checker++){
          if(ref[that.uploaded_list[line_checker].ID].selected == selection_parent1.toUpperCase()) {
            parents_mcp_exist = true;
          } else if (ref[that.uploaded_list[line_checker].ID].selected == selection_parent2.toUpperCase()) {
            parents_bcs_exist = true;
          } else if (ref[that.uploaded_list[line_checker].ID].selected == selection_parent3.toUpperCase()) {
            parents_ppid_exist = true;
          } else if (ref[that.uploaded_list[line_checker].ID].selected == selection_parent4.toUpperCase()) {
            parents_govid_exist = true;
          }
        }
        if(parents_bcs_exist && parents_mcp_exist && parents_ppid_exist && parents_govid_exist) {
          that.message="";
          state.membership_details.uploaded_list_error = false;
        }else{
          state.membership_details.uploaded_list_error = true;
          if(!parents_mcp_exist) {
            that.message = "Missing " + selection_parent1;
          }
           if(!parents_bcs_exist) {
            that.message = "Missing " + selection_parent2;
          }
           if(!parents_ppid_exist) {
            that.message = "Missing " + selection_parent3;
          }
           if(!parents_govid_exist) {
            that.message = "Missing " + selection_parent4;
          }
        }
      }

    },

    checkDependentsSpouse() {
      let that = this;
      let ref = that.$refs;
      let state = that.$store.state;

      let spouse_mc = false;
      let spouse_gov_id = false;
      let spouse_ppid_exist = false;

      let selection_spouse1 = "Marriage Contract";
      let selection_spouse2 = "Photocopy of Government Issued ID";
      let selection_spouse3 = "Photocopy of PAFCPIC ID of Sponsor";

      if(state.membership_details.branch_of_service=="DEP" && that.dependent_type_selected == "SPOUSE") {
         for(let line_checker = 0 ; line_checker < that.uploaded_list.length; line_checker++){
          if(ref[that.uploaded_list[line_checker].ID].selected == selection_spouse1.toUpperCase()) {
            spouse_mc = true;
          } else if (ref[that.uploaded_list[line_checker].ID].selected == selection_spouse2.toUpperCase()) {
            spouse_gov_id = true;
          } else if (ref[that.uploaded_list[line_checker].ID].selected == selection_spouse3.toUpperCase()) {
            spouse_ppid_exist = true;
          } 
        }
        if(spouse_mc && spouse_gov_id && spouse_ppid_exist) {
          that.message="";
          state.membership_details.uploaded_list_error = false;
        }else{
          state.membership_details.uploaded_list_error = true;
          if(!spouse_mc) {
            that.message = "Missing " + selection_spouse1;
          }
           if(!spouse_gov_id) {
            that.message = "Missing " + selection_spouse2;
          }
           if(!spouse_ppid_exist) {
            that.message = "Missing " + selection_spouse3;
          }
        }
      }

    },
    checkDependentsChild(){
      let that = this;
      let ref = that.$refs;
      let state = that.$store.state;

      let child_bc_exist = false;
      let mcps_exist = false;
      let ppidc_exist = false;
      let ppids_exist = false;
      let mc_exist = false;

      let selection_child1 = "Marriage Contract of Parents";
      let selection_child2 = "Birth Certificate";
      let selection_child3 = "Photocopy of PAFCPIC ID of Sponsor";
      let selection_child4 = "Photocopy of valid ID";
      let selection_child5 = "Marriage Contract(for married female child)";


that.selection = ["Marriage Contract of Parents","Birth Certificate","Photocopy of PAFCPIC ID of Sponsor","Photocopy of valid ID","Marriage Contract(for married female child)","Others"]

      if(state.membership_details.branch_of_service=="DEP" && that.dependent_type_selected == "CHILD") {
         for(let line_checker = 0 ; line_checker < that.uploaded_list.length; line_checker++){
          if(state.membership_details.civil_status == "mr"){
            if(ref[that.uploaded_list[line_checker].ID].selected == selection_child1.toUpperCase()) {
            child_bc_exist = true;
            } else if (ref[that.uploaded_list[line_checker].ID].selected == selection_child2.toUpperCase()) {
              mcps_exist = true;
            } else if (ref[that.uploaded_list[line_checker].ID].selected == selection_child3.toUpperCase()) {
              ppidc_exist = true;
            } else if (ref[that.uploaded_list[line_checker].ID].selected == selection_child4.toUpperCase()) {
              ppids_exist = true;
            }  else if (ref[that.uploaded_list[line_checker].ID].selected == selection_child5.toUpperCase()) {
              mc_exist = true;
            } 
          }else{
            if(ref[that.uploaded_list[line_checker].ID].selected == selection_child1.toUpperCase()) {
              child_bc_exist = true;
            } else if (ref[that.uploaded_list[line_checker].ID].selected == selection_child2.toUpperCase()) {
              mcps_exist = true;
            } else if (ref[that.uploaded_list[line_checker].ID].selected == selection_child3.toUpperCase()) {
              ppidc_exist = true;
            } else if (ref[that.uploaded_list[line_checker].ID].selected == selection_child4.toUpperCase()) {
              ppids_exist = true;
            } 
          }
          
        }
        if(state.membership_details.civil_status == "mr"){
          if(child_bc_exist && mcps_exist && ppidc_exist && ppids_exist && mc_exist) {
          state.membership_details.uploaded_list_error = false;
          that.message = "";
          }else{
            state.membership_details.uploaded_list_error = true;
            if(!child_bc_exist) {
              that.message = "Missing " + selection_child1;
            }
            if(!mcps_exist) {
              that.message = "Missing " + selection_child2;
            }
            if(!ppidc_exist) {
              that.message = "Missing " + selection_child3;
            }
            if(!ppids_exist) {
              that.message = "Missing " + selection_child4;
            }
            if(!mc_exist) {
              that.message = "Missing " + selection_child5;
            }
          }
        }else{
           if(child_bc_exist && mcps_exist && ppidc_exist && ppids_exist) {
             state.membership_details.uploaded_list_error = false;
             that.message = "";
          } else { 
            state.membership_details.uploaded_list_error = true;
             if(!child_bc_exist) {
              that.message = "Missing " + selection_child1;
            }
            if(!mcps_exist) {
              that.message = "Missing " + selection_child2;
            }
            if(!ppidc_exist) {
              that.message = "Missing " + selection_child3;
            }
            if(!ppids_exist) {
              that.message = "Missing " + selection_child4;
            }
          } 
        }
        
      }
    },
    checkUploadedFiles() {
      let that = this;
      let ref = that.$refs;
      let state = that.$store.state;
      
      if(that.uploaded_list.length>0) {
        for(let line_checker = 0 ; line_checker < that.uploaded_list.length; line_checker++){
          ref[that.uploaded_list[line_checker].ID].checkEmpty();
          if(ref[that.uploaded_list[line_checker].ID].selected == "") {
            state.membership_details.uploaded_list_error = true;
            break;
          } else {
            state.membership_details.uploaded_list_error = false;
          }
       }
        // console.log(true);
        that.checkActive();
        that.checkRetired();

        if(that.dependent_type_selected == "PARENTS"){
            that.checkDependentsParents();
        } else if(that.dependent_type_selected == "SPOUSE"){
            that.checkDependentsSpouse();
        } else if(that.dependent_type_selected == "CHILD"){
            that.checkDependentsChild();
        }

      } else {
        state.membership_details.uploaded_list_error = true;
      }
    },
    handleChange(info){
      let that = this;
      that.uploaded_list = [];
      for(let i = 0 ; i < info.fileList.length ; i++) {
        // console.log(info.fileList);
        let item = {
          ID:info.fileList[i].uid,
          REMARKS:"",
          DATA:info.fileList[i].thumbUrl,
          DATE:moment(info.fileList[i].lastModified).format("MM/DD/YYYY"),
          TIME:moment(info.fileList[i].lastModified).format("hh:mm:ss A")
        }
        getBase64(info.fileList[i].originFileObj, imageUrl => {
          item.data = imageUrl;
        });
        // console.log(item);
        that.uploaded_list.push(item);
      }
      // console.log(that.uploaded_list);
      that.updateUploadedListAll();
    },
    submitList(){
      let that = this;
      let state = that.$store.state.membership_details;

      state.uploaded_requirements = that.uploaded_list;
      // console.log(state.uploaded_requirements);
    },
    hideFields(id){
      let that = this;
      let ref = that.$refs;
      ref[id].hideField();
    },
    check(){
      let that = this;
      let store = that.$store;
      let state = store.state.membership_details;
      that.membership_type = state.mem_type;
      if(state.mem_type == "ASSOCIATE" || state.mem_type == "DEP"){
        that.disable_sc = true;
        that.disable_sc_check = true;
      }

    },
    showDependentId(){
     let that = this;
      let store = that.$store;
      let state = store.state;
      if(state.membership_details.branch_of_service == "DEP"){
          that.show_dependent_id = true;
      }else{
        that.show_dependent_id = false;
      }
    },
    updateSc(e){
      let that = this;
      let store = that.$store;
      let state = store.state; 
      let ref = that.$refs;
      state.membership_details.sc_agree = e == true? 1: 0;
      that.sc_agree = e == true? 1: 0;
      if(e == true && state.membership_details.membership_type != "ASSOCIATE"){
        that.sc = 500;
        ref.sc.changedInitial(that.sc);
      }else{
        that.sc = 0;
        ref.sc.changedInitial(that.sc);
      }
    },

    updateWcp(e){
      let that = this;
      let store = that.$store;
      let state = store.state; 
      that.wcp_agree = e == true? 1: 0;
      state.membership_details.wcp_agree = e == true? 1: 0;
      
    },

    updateCisa(e){
      let that = this;
      let store = that.$store;
      let state = store.state; 

      that.cisa_agree = e == true? 1: 0;
      state.membership_details.cisa_agree = e == true? 1: 0;
      
    },
    update(e) {
          // console.log(e);
          let key = e.key;
          let that = this;
          let store = that.$store;
          let state = store.state;

          that.error_message = false;
          that[key] = e.value;
          if(key=='branch_code') {
            that.branch_name = e.value;
            that.branch_code = that.getBranchCode(e.value);
            that.$refs.IntDate.clearDate();
            that.$refs.interview.clearValue();
            state.membership_details.interview_date = null;
            state.membership_details.schedule = null;
          }
          if(key == 'interview_date') {
            that.$refs.interview.clearValue();
            that.pushTimeList(); 
          }
          if(key == 'interview_time') {
            that.getSchedKey(e.value);
          }
          if(key == 'interview_mode'){
              if(e.value == 'ZOOM'){
                state.membership_details.interview_mode = 'ZOM'
              }
               if(e.value == 'SIGNAL'){
                state.membership_details.interview_mode = 'SIG'
              }
               if(e.value == 'VIBER'){
                state.membership_details.interview_mode = 'VIB'
              }
          }
          that.show_selfie_modal =  false;
          that.show_signature_pad = false;
          state.membership_details.snap = that.selfie;
          state.membership_details.sc = that.sc;
          state.membership_details.interview_date = that.interview_date;
          state.membership_details.pso = that.branch_code;
          state.membership_details.signature = that.signature;
          state.membership_details.front = that.front_id;
          state.membership_details.back = that.back_id;
          state.membership_details.ref_account = that.ref_account;
          state.membership_details.ref_name = that.ref_name;
          state.membership_details.sponsor_id = that.sponsor_id;
          state.membership_details.sc_agree = that.sc_agree;
          state.membership_details.cisa_agree = that.cisa_agree;
          state.membership_details.wcp_agree = that.wcp_agree;
          state.membership_details.confirmed = moment(state.references.server_time).format('MM/DD/YYYY');
        },
        addSpouse() {
            let spouse_count = this.spouse_list.length;
            if(spouse_count < 4){
               this.spouse_list.push('spouse'+spouse_count);
            }
        },
        removeSched(){
            let that = this;
            let date = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
            
            // console.log(that.new_list);
              that.scheds.forEach((item) => {
                  if(date>item) {
                    // console.log(item,index);
                    that.new_list.splice(0,1);
                  }
              });

              
        },
         pushTimeList(){
            let that = this;
            let store = that.$store;
            let state = store.state;
            let references = state.references.branches;
            let schedule_list_array=[];
            that.interview_time_list = [];
            let new_date=null;
            let inline_time =0
            let inline_date =null;
            // let today = ;
            // let server_time = moment(state.references.server_time).format('YYYY-MM-DDTHH:mm:ss');
            schedule_list_array=references.find(d => d.code == that.branch_code);

            if(schedule_list_array != undefined) {
              schedule_list_array.schedule.forEach(element => {
                  if(that.interview_date.toString() == element.date.toString()) {
                    
                    if(element.to.substring(6,8)=="PM" && element.to.substring(0,2)!= "12"){
                      inline_time = parseInt(element.to.substring(0,2)) + 12;
                      inline_date = element.date.toString() + "T"+inline_time+element.to.substring(2,5)+':00';
                    }else if(element.to.substring(6,8)=="AM"){
                        inline_time =element.to.substring(0,2);
                        inline_date = element.date.toString() + "T"+inline_time+element.to.substring(2,5)+':00';
                    }
                    new_date = moment(inline_date).format('YYYY-MM-DDTHH:mm:ss');
                      // console.log(moment((new_date)).format('YYYY-MM-DDTHH:mm:ss'),moment(state.references.server_time).format('YYYY-MM-DDTHH:mm:ss'));
                      that.interview_time_list.push(element.from + ' - ' + element.to);
                      // that.from= element.from;
                      // that.to=element.to;
                      that.scheds.push(new_date.toString());
                }
              });
              // console.log(that.interview_time_list);
              that.new_list = that.interview_time_list;
              that.removeSched();
            }

        },
        openCamera(){
          this.$emit('openCamera');
        },
        saveSelfie(e){
          this.$emit('saveSelfie',{
              key:e.key,
              value:e.value,
            });
        },
        getSchedKey(interview_time){
          let that = this;
          let store = that.$store;
          let state = store.state;
          let references = state.references.branches;
          let schedule_list_array=[];
          schedule_list_array =references.find(d => d.code == that.branch_code);
          let from = interview_time.substring(0, 8);
          let to = interview_time.substring(11, 20);
          // console.log(from);
          // console.log(to);
          
          if(schedule_list_array != undefined){
            for(let i = 0; i < schedule_list_array.schedule.length; i++){
              // console.log(that.interview_date.toString());
              // console.log(schedule_list_array.schedule[i].to);
                if(that.interview_date.toString() == schedule_list_array.schedule[i].date.toString() && schedule_list_array.schedule[i].from == from && schedule_list_array.schedule[i].to == to) {
                    // console.log(schedule_list_array.schedule[i].from);
                    state.membership_details.schedule = schedule_list_array.schedule[i].key;
                }
                
            }
          }
        },
        removeSpouse(e){
          this.spouse_list.splice(e,1);
        },
        getBranchCode(e){
          let that = this;
          let store = that.$store;
          let state = store.state;
          let references = state.references;
          for(let index = 0; index < references.branches.length ; index++) {
            let branch_name = references.branches[index].label.replace('PAFCPIC SATELLITE  OFFICE - ','');
                branch_name = branch_name.replace('PAFCPIC SATELLITE OFFICE - ','');
            if(branch_name  == e){
              state.membership_details.pso_text=references.branches[index].label;
              that.schedule = references.branches[index].schedule;
              that.changeCalendar();
              return references.branches[index].code;
            }
          }
        },
        changeCalendar(){
          let that = this;
          that.enabled_dates = [];
          for(let i = 0; i<that.schedule.length ; i++){
            that.enabled_dates.push(that.schedule[i].date);
          }
        },
        checkEmpty(){
          if(this.selfie == null || this.selfie ==''){
              this.selfie_empty =true;
          }else {
            this.selfie_empty = false;
          }
          if(this.signature == null || this.signature == ''){
            this.signature_empty = true;
          }else{
            this.signature_empty = false;
          }
        },
        checkFields() {
        let ref = this.$refs;
        let store = this.$store;
        let state = store.state.membership_details;
        ref.Branch.checkEmpty();
        ref.IntDate.checkEmpty();
        ref.frontImage.checkEmpty();
        ref.backImage.checkEmpty();
        if(this.sponsor_id != null){
          ref.sponsor_id.checkEmpty();
        }
        ref.interview.checkEmpty();
        ref.interview_mode.checkEmpty();

        if(state.sc_agree == 1) {
          ref.sc.checkEmpty();
        }
        // ref.signature.checkEmpty();
      },
  },
  watch:{
    fileList(n){
      setTimeout(() => {
          for(let index = 0 ; index<n.length; index++){
          // console.log(n[index]);
          this.hideFields(n[index].uid+"1");
        }
      }, 100);
       
    }
  }
})
</script>
<style>
.max_height {
  height: calc(70% - 420px) !important;
}
.static_modal {
  position: static !important;
}
.ant-image {
  align-content:center !important;

}
</style>